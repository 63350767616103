const theme = {
    fonts: {
        main: 'Rubik, sans-serif',
    },
    colors: {
        dark: '#212121',
        primary: '#2CA8DE',
        navbarBgPrimary: '#fff',
        white: '#fff',
        transparent: 'transparent',
        blue: '#154683',
        navbarTextPrimary: '#0C1A68',
    },
    fontsize: {
        heading: '56px',
        subHeading: '40px',
    },
};

export default theme;
